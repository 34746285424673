import uuid from "uuid/v4";
import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART,
  FETCH_CARTS_SUCCESS
} from "../actions/cartActions";

const initState = [];

const cartReducer = (state = initState, action) => {
  // //console.log(state)
  const cartItems = state;
  const  product = action.payload;

  if (action.type === ADD_TO_CART) {
    // for non variant products
    if (product.variation === undefined) {
      const cartItem = cartItems.filter(item => item.product_id === product.product_id)[0];
      if (cartItem === undefined) {
        return [
          ...cartItems,
          {
            ...product,
            qty: product.qty ? product.qty : 1,
            cartItemId: uuid()
          }
        ];
      } else {
        return cartItems.map(item =>
          item.id === cartItem.id
            ? {
                ...item,
                qty: product.qty
                  ? parseInt(item.qty) + parseInt(product.qty)
                  : parseInt(item.qty) + 1
              }
            : item
        );
      }
      // for variant products
    } else {
      const cartItem = cartItems.filter(
        item =>
          item.product_id === product.product_id &&
          product.selectedProductColor &&
          product.selectedProductColor === item.selectedProductColor &&
          product.selectedProductSize &&
          product.selectedProductSize === item.selectedProductSize &&
          (product.cartItemId ? product.cartItemId === item.cartItemId : true)
      )[0];

      if (cartItem === undefined) {
        return [
          ...cartItems,
          {
            ...product,
            qty: product.qty ? product.qty : 1,
            cartItemId: uuid()
          }
        ];
      } else if (
        cartItem !== undefined &&
        (cartItem.selectedProductColor !== product.selectedProductColor ||
          cartItem.selectedProductSize !== product.selectedProductSize)
      ) {
        return [
          ...cartItems,
          {
            ...product,
            qty: product.qty ? product.qty : 1,
            cartItemId: uuid()
          }
        ];
      } else {
        return cartItems.map(item =>
          item.product_id === cartItem.product_id
            ? {
                ...item,
                qty: product.qty
                  ? item.qty + product.qty
                  : item.qty + 1,
                selectedProductColor: product.selectedProductColor,
                selectedProductSize: product.selectedProductSize
              }
            : item
        );
      }
    }
  }

  if (action.type === DECREASE_QUANTITY) {
    if (parseInt(product.qty) === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          cartItem => cartItem.product_id !== product.product_id
        );
      return remainingItems(cartItems, product);
    } else {
      return cartItems.map(item =>
        item.product_id === product.product_id
          ? { ...item, qty: item.qty - 1 }
          : item
      );
    }
  }

  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) =>
      cartItems.filter(cartItem => cartItem.product_id !== product.product_id);
    return remainingItems(cartItems, product);
  }

  if (action.type === DELETE_ALL_FROM_CART) {
    return cartItems.filter(item => {
      return false;
    });
  }

  if (action.type === FETCH_CARTS_SUCCESS) {
    return (
      action.payload
    );
  }

  return state;
};

export default cartReducer;
