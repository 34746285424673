import instance from "../axios";

export const guestRegister = async () => {   
    const response = await instance({
        method: 'post',
        url: 'auth/GuestRegister.php',
        headers: {'Content-Type': 'multipart/form-data' }
    });
    return response.data;
}
;
export const tokenRecreate = async () => {   
    const response = await instance({
        method: 'post',
        url: 'auth/reCreate.php',
        headers: {'Content-Type': 'multipart/form-data' }
    });
    return response.data;
};

export const updateGuestToUser = async (name, phone, email, password) => {   
    var bodyFormData = new FormData();
    bodyFormData.append('name', name)
    bodyFormData.append('phone', phone)
    bodyFormData.append('email', email)
    bodyFormData.append('password', password)
    const response = await instance({
        method: 'post',
        url: 'auth/UpdateGuestToUser.php',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
    });
    return response.data;
};

export const Update = async (name, phone, email) => {   
    var bodyFormData = new FormData();
    bodyFormData.append('name', name)
    bodyFormData.append('phone', phone)
    bodyFormData.append('email', email)
    const response = await instance({
        method: 'post',
        url: 'users/Update.php',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
    });
    return response.data;
};

export const UpdatePassword = async (oldPassword, password) => {   
    var bodyFormData = new FormData();
    bodyFormData.append('oldPassword', oldPassword)
    bodyFormData.append('password', password)
    const response = await instance({
        method: 'post',
        url: 'users/UpdatePassword.php',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
    });
    return response.data;
};

export const Login = async (email, password) => {   
    var bodyFormData = new FormData()
    bodyFormData.append('email', email)
    bodyFormData.append('password', password)
    const response = await instance({
        method: 'post',
        url: 'auth/UserLogin.php',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
    });
    return response.data;

};

export const GetByID = async () => {   
    const response = await instance({
        method: 'post',
        url: 'users/GetByID.php',
        headers: {'Content-Type': 'multipart/form-data' }
    });
    return response.data;
};
